import { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material"
import { identifyAccounts as identifyAccountsAPI, addColumnSynonym } from "../../../utils/api";
import ProcessFileContext from "../../../contexts/processFileContext";

const ColumnSynonimContent = () => {
    const { missingColumns, identifyAccounts } = useContext(ProcessFileContext)
    const [formValues, setFormValues] = useState([])
    const disableSubmit = formValues.some(({ columnSynonim }) => !columnSynonim)

    const handleSubmit = () => {
        const payload = formValues.map(({ column, columnSynonim }) => ({ column_id: column.id, description: columnSynonim }))

        addColumnSynonym(payload).then(response => {
            identifyAccounts()
        }).catch(err => {
            console.log(err)
        })
    }

    const handleChange = (evt, index) => {
        const { value } = evt.target

        const newFormValues = [...formValues]
        newFormValues[index] = { ...newFormValues[index], columnSynonim: value }

        setFormValues(newFormValues)
    }

    useEffect(() => {
        setFormValues(missingColumns.map(missingColumn => ({ column: missingColumn, columnSynonim: "" })))
    }, [missingColumns])

    return (
        <Grid container justify="center" direction="column" gap={4} sx={{ width: "800px" }}>
            <Typography variant="h5"><strong>Sinônimos de colunas</strong></Typography>
            {formValues.length ?
                <Typography variant="body1">Não foram encontrados sinônimos na planilha para as seguintes colunas:</Typography>
                : <Typography variant="body1">Todas as colunas necessárias para o processamento estão cadastradas</Typography>
            }
            {formValues.map(({ column, columnSynonim }, index) => (
                <Grid container item gap={4} wrap="nowrap" key={column.id}>
                    <TextField
                        name="column"
                        label="Coluna"
                        variant="standard"
                        value={column.name}
                        disabled
                        style={{ width: '100%' }}
                    />
                    <TextField
                        name="columnSynonim"
                        label="Sinônimo"
                        variant="standard"
                        value={columnSynonim}
                        style={{ width: '100%' }}
                        onChange={(evt) => handleChange(evt, index)}
                    />
                </Grid>
            ))}
            {formValues.length ?
                <>
                    <Box mt={1} />
                    <Button variant="contained" disabled={disableSubmit} onClick={handleSubmit}>Adicionar</Button>
                </>
                : null}
        </Grid>
    )
}

export default ColumnSynonimContent