import { useContext, useEffect, useState } from "react";
import { Autocomplete, Box, Button, FormControl, Grid, Input, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import Divider from '../../../components/Divider'
import { ClipLoader } from 'react-spinners'
import { getWorkGroups as getWorkGroupsAPI, getLedgerAccountByName, editLedgerAccount as editLedgerAccountAPI } from "../../../utils/api";
import ProcessFileContext from "../../../contexts/processFileContext";
import './ledger-accounts-edition-content.scss'

const LedgerAccountEditionContent = () => {
    const { missingColumns, identifiedAccounts, suggestedAccounts } = useContext(ProcessFileContext)
    const [ledgerAccountsOptions, setLedgerAccountsOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedLedgerAccount, setSelectedLedgerAccount] = useState(null)
    const [editingAccounts, setEditingAccounts] = useState([])
    const [workGroups, setWorkgroups] = useState([])

    const handleLedgerAccountChange = (_, value) => {
        if (value) {
            setSelectedLedgerAccount(value)
            setIsLoading(true)

            getLedgerAccountByName(value).then(({ data }) => {
                setIsLoading(false)
                setEditingAccounts([...editingAccounts, { description: data["description"], workGroup: data?.work_group?.name || 'out_of_scope' }])
                setSelectedLedgerAccount(null)
            })
        }
    }

    const onWorkGroupChange = (evt, description) => {
        const { value } = evt.target

        const newEditingAccountsValue = [...editingAccounts.map(account => {
            if (account.description === description) {
                account.workGroup = value
            }

            return account
        })]

        setEditingAccounts(newEditingAccountsValue)
    }

    const getWorkGroups = () => {
        getWorkGroupsAPI().then(({ data }) => setWorkgroups([...data.map(({ name }) => ({ label: name, value: name })), { label: 'fora de escopo', value: 'out_of_scope' }])).catch(err => console.log(err))
    }

    const handleSubmitClick = () => {
        const payload = editingAccounts.map(({ description, workGroup }) => ({ description, work_group: workGroup }))
        editLedgerAccountAPI(payload).then(data => setEditingAccounts([])).catch(err => console.log(err))
    }

    useEffect(() => {
        const editingAccountsDescription = editingAccounts.map(({ description }) => description)
        const filteredLedgerAccountsOptions = identifiedAccounts.filter(account => !editingAccountsDescription.includes(account))

        setLedgerAccountsOptions(filteredLedgerAccountsOptions)
    }, [editingAccounts])

    useEffect(() => {
        setLedgerAccountsOptions(identifiedAccounts)
        getWorkGroups()
    }, [])

    return (
        <Grid container direction="column" wrap="nowrap" style={{ maxHeight: '500px', width: '800px' }}
        >
            <Typography variant="h5"><strong>Configuração contas razão</strong></Typography>
            <Box mb={2} />
            {missingColumns.length > 0 || Object.values(suggestedAccounts).length > 0 ?
                <>
                    <Box mb={4} />
                    <Typography variant="body1">Conclua os passos anteriores para prosseguir.</Typography>
                </>
                :
                <>
                    <Grid container item alignItems="center">
                        <Autocomplete
                            disablePortal
                            options={ledgerAccountsOptions}
                            value={selectedLedgerAccount}
                            disabled={isLoading}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Selecionar conta razão"
                                />
                            )}
                            onChange={handleLedgerAccountChange}
                            noOptionsText="Não foi encontrada essa conta razão"
                        />
                        <Box ml={2} />
                        {isLoading ? <ClipLoader size={24} color="#b9b9b9" /> : null}
                    </Grid>
                    {editingAccounts.length > 0 &&
                        <>
                            <Box mb={3} />
                            <Grid container direction="column" gap={4} wrap="nowrap" className="ledger-accounts-edition-content-wrapper">
                                {editingAccounts.map(({ description, workGroup }) => (
                                    <Grid container gap={4} style={{ width: "100%" }} wrap="nowrap" key={description}>
                                        <Grid item
                                            style={{ width: '50%' }}>
                                            <TextField label="Conta Razão"
                                                variant="standard" value={description} disabled sx={{ width: "100%" }} />
                                        </Grid>
                                        <Grid item style={{ width: '50%' }}>
                                            <FormControl variant="standard" style={{ width: "100%" }}>
                                                <InputLabel id="work-group-label">Grupo de Trabalho</InputLabel>
                                                <Select
                                                    labelId="work-group-label"
                                                    value={workGroup}
                                                    onChange={(evt) => onWorkGroupChange(evt, description)}
                                                >
                                                    {workGroups.map(({ label, value }) => {
                                                        return <MenuItem value={value} key={value}>{label}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>)
                                )}
                            </Grid>
                        </>
                    }
                    <Box mt={4} />
                    <Button variant="contained" onClick={handleSubmitClick}>Configurar</Button>
                </>
            }
        </Grid>
    )
}

export default LedgerAccountEditionContent