import axiosInstance from "./apiConfig";

export const login = async (data) => {
    try {
        const response = await axiosInstance.post('/login', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const refreshToken = async () => {
    try {
        const response = await axiosInstance.post('/refresh-token');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const logout = async () => {
    try {
        const response = await axiosInstance.post('/logout');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const checkStatus = async () => {
    return await axiosInstance.get(`/`)
}

export const uploadFile = async (file) => {
    return await axiosInstance.post('/upload-file', file, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}

export const processFile = async (filepath, processingSteps) => {
    return await axiosInstance.post('/process-file', { file_path: filepath, processing_steps: processingSteps }, {
        responseType: 'blob'
    })
}

export const getLedgerAccounts = async () => {
    return await axiosInstance.get('/ledger-accounts')
}

export const getLedgerAccountByName = async (description) => {
    return await axiosInstance.get(`/ledger-account/?description=${description}`)
}

export const addLedgerAccount = async (payload) => {
    return await axiosInstance.post(`/ledger-accounts`, payload)
}

export const editLedgerAccount = async (payload) => {
    return await axiosInstance.put('/ledger-accounts', payload)
}

export const deleteLedgerAccount = async (id) => {
    return await axiosInstance.delete(`/ledger-accounts/${id}`)
}

export const getColumnSynonyms = async () => {
    return await axiosInstance.get('/column-synonyms')
}

export const addColumnSynonym = async (payload) => {
    return await axiosInstance.post(`/column-synonyms`, payload)
}

export const deleteColumnSynonym = async (id) => {
    return await axiosInstance.delete(`/column-synonyms/${id}`)
}

export const getOutLedgerAccounts = async () => {
    return await axiosInstance.get('/out-ledger-account')
}

export const addOutLedgerAccount = async (payload) => {
    return await axiosInstance.post(`/out-ledger-account`, payload)
}

export const deleteOutLedgerAccount = async (id) => {
    return await axiosInstance.delete(`/out-ledger-account/${id}`)
}

export const getWorkGroups = async () => {
    return await axiosInstance.get('/work-groups')
}

export const addWorkGroups = async (payload) => {
    return await axiosInstance.post(`/work-groups`, payload)
}

export const deleteWorkGroup = async (id) => {
    return await axiosInstance.delete(`/work-groups/${id}`)
}

export const identifyAccounts = async (filepath, processingSteps) => {
    return await axiosInstance.post(`/identify-accounts`, { file_path: filepath, processing_steps: processingSteps })
}


export const saveIdentifiedAccounts = async (identifiedAccounts) => {
    return await axiosInstance.post(`/save-identified-accounts`, identifiedAccounts)
}

export const userRegister = async (payload) => {
    try {
        const response = await axiosInstance.post('/users', payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const userUpdate = async (payload, userId) => {
    try {
        const response = await axiosInstance.put(`/users/${userId}`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const listUsers = async (queryParams) => {
    try {
        const response = await axiosInstance.get('/users', {
            params: queryParams
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getUser = async (userId) => {
    try {
        const response = await axiosInstance.get(`/users/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteUser = async (userId) => {
    try {
        const response = await axiosInstance.delete(`/users/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {}
