import React, { useContext, useEffect, useState } from "react";
import { Grid, Button, Box, TextField, FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material"
import { saveIdentifiedAccounts, getWorkGroups as getWorkGroupsAPI } from "../../../utils/api"
import ProcessFileContext from "../../../contexts/processFileContext";
import "./identified-accounts-content.scss"

const row = (account, workGroups, selectedWorkgroups, setSelectedWorkGroups) => {
    const onWorkGroupChange = ({ target }, account) => {
        const selectedWorkGroup = target.value
        const newSelectedWorkGroups = [...selectedWorkgroups.map(value => {
            if (value.description === account.description) {
                value.workGroup = selectedWorkGroup
            }

            return value
        })]

        setSelectedWorkGroups(newSelectedWorkGroups)
    }

    return (
        <Grid container gap={4} style={{ width: "100%" }} wrap="nowrap" key={account.description}>
            <Grid item
                style={{ width: '50%' }}>
                <TextField
                    id="name"
                    name="columnSynonym"
                    label="Conta Razão"
                    variant="standard"
                    value={account.description}
                    disabled={true}
                    shrink="true"
                    style={{ width: '100%' }}
                />
            </Grid>
            <Grid item style={{ width: '50%' }}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-standard-label">Grupo de Trabalho</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        name="column"
                        value={account.workGroup}
                        onChange={(evt) => onWorkGroupChange(evt, account)}
                    >
                        {workGroups.map(({ value, label }) => {
                            return <MenuItem value={value} key={value}>{label}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

const IdentifiedAccountsContent = () => {
    const { missingColumns, identifyAccounts, suggestedAccounts } = useContext(ProcessFileContext)
    const [selectedWorkgroups, setSelectedWorkGroups] = useState([])
    const [workGroups, setWorkGroups] = useState([])

    const handleSubmit = () => {
        const preparedIdentifiedAccounts = selectedWorkgroups.reduce((acc, item) => {
            if (!acc[item.workGroup]) {
                acc[item.workGroup] = [];
            }
            acc[item.workGroup].push(item.description);
            return acc;
        }, {});

        saveIdentifiedAccounts(preparedIdentifiedAccounts).then(response => {
            identifyAccounts()
        }).catch(err => console.log(err))
    }

    const getWorkGroups = () => {
        getWorkGroupsAPI().then(({ data }) => setWorkGroups([...data.map(({ name }) => ({ label: name, value: name })), { label: 'fora de escopo', value: 'out_of_scope' }])).catch(err => console.log(err))
    }

    useEffect(() => {
        setSelectedWorkGroups(Object.entries(suggestedAccounts).reduce((acc, workGroup) => {
            const mappedWorkGroup = workGroup[1].map(value => ({ workGroup: workGroup[0], description: value }))
            return ([...acc, ...mappedWorkGroup].flat())
        }, []))
    }, [suggestedAccounts])

    useEffect(() => {
        getWorkGroups()
    }, [])


    return (
        <Grid container direction="column" wrap="nowrap" style={{ maxHeight: '400px', width: '800px' }}
        >
            <Typography variant="h5"><strong>Contas razão não categorizadas</strong></Typography>

            {missingColumns.length > 0 ?
                <>
                    <Box mb={4} />
                    <Typography variant="body1">Conclua a fase de sinônimos para prosseguir.</Typography>
                </>
                : Object.values(suggestedAccounts).length > 0 ?
                    <>
                        <Box mt={2} />
                        <Grid container direction="column" gap={4} wrap="nowrap" className="identified-accounts-content-wrapper">
                            {selectedWorkgroups.map(account => row(account, workGroups, selectedWorkgroups, setSelectedWorkGroups))}
                        </Grid>
                    </>
                    : <>
                        <Box mb={4} />
                        <Typography variant="body1">Todas as contas razão foram categorizadas, você já pode processar o arquivo!</Typography>
                    </>
            }
            {(Object.values(suggestedAccounts).length > 0 && missingColumns.length === 0) &&
                <>
                    <Box mt={4} />
                    <Button variant="contained" onClick={handleSubmit}>Adicionar</Button>
                </>
            }
        </Grid>
    )
}

export default IdentifiedAccountsContent