import { useContext, useEffect } from "react";
import { Box, Button, Card, Divider, Grid, Skeleton, Typography } from "@mui/material"
import ColumnSynonimContent from "../ColumnSynonimContent";
import IdentifiedAccountsContent from "../IdentifiedAccountsContent";
import LedgerAccountEditionContent from "../LedgerAccountsEditionContent";
import ProcessFileContext from "../../../contexts/processFileContext";
import StepperContext from "../../../contexts/stepperContext";
import { ClipLoader } from "react-spinners";
import { processFile as processFileAPI } from "../../../utils/api";

const PreProcessingContentSkeleton = () => {
    return (
        <>
            <Typography variant="h5"><Skeleton width={300} /></Typography>
            <Box mb={4} />
            <Typography variant="body1"><Skeleton width={800} /></Typography>
            <Box mb={4} />
            <Skeleton width={800} height={5} />
            <Box mb={4} />
            <Typography variant="h5"><Skeleton width={300} /></Typography>
            <Skeleton width={800} height={200} />
            <Box mb={4} />
            <Skeleton width={800} height={50} />
        </>
    )
}

const PreProcessingContent = () => {
    const { missingColumns, suggestedAccounts, identifyAccounts, uploadedFilePath, processingSteps, setProcessedFilename, setResponseFileURL, isLoading, setIsLoading, willEditLedgerAccount } = useContext(ProcessFileContext)
    const { goNext, resetStepper } = useContext(StepperContext)

    const parseFilename = (filename) => {
        const regex = /filename\*\=utf-8''(.+)/;

        const match = filename.match(regex);

        if (match && match[1]) {
            const fileName = decodeURIComponent(match[1]);
            return fileName
        } else {
            return 'arquivo_processado'
        }
    }

    const processFile = () => {
        setIsLoading(true)
        processFileAPI(uploadedFilePath, processingSteps).then(response => {
            const file = new Blob([response.data], { type: response.data.type });
            const fileURL = URL.createObjectURL(file);
            setProcessedFilename(parseFilename(response.headers['content-disposition']))
            setResponseFileURL(fileURL)
            setIsLoading(false)
            goNext()
        }).catch(({ response }) => {
            setIsLoading(false)
        })
    }

    const handleProcessFileClick = () => {
        processFile()
    }

    useEffect(() => {
        identifyAccounts()
    }, [])

    return (
        <Card sx={{ padding: "48px", borderRadius: "8px" }}>
            {isLoading ?
                <PreProcessingContentSkeleton />
                :
                <>
                    <ColumnSynonimContent />
                    <Box mb={6} />
                    <Divider />
                    <Box mb={6} />
                    <IdentifiedAccountsContent />
                    <Box mb={6} />
                    <Divider />
                    <Box mb={6} />
                    {willEditLedgerAccount === "y" &&
                        <>
                            <LedgerAccountEditionContent />
                            <Box mb={6} />
                            <Divider />
                            <Box mb={6} />
                        </>
                    }
                    {missingColumns.length === 0 && Object.values(suggestedAccounts).length === 0 &&
                        <>
                            <Box mb={8} />
                            <Grid container justifyContent="space-between">
                                <Button variant="outlined" color="primary" onClick={resetStepper}>
                                    Reiniciar o Fluxo
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleProcessFileClick}>
                                    Iniciar o Processamento
                                </Button>
                            </Grid>
                        </>
                    }
                </>
            }
        </Card >
    )
}

export default PreProcessingContent;