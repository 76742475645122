import React, { useState, useEffect } from "react";
import { getWorkGroups as getWorkGroupsAPI, addWorkGroups as addWorkGroupsAPI } from "../../utils/api";
import MainLayout from "../../layouts";
import WorkGroupsTable from '../../components/WorkGroupsTable'
import { TextField, Grid, Box, Button } from "@mui/material";
import Modal from '../../components/BaseModal'

function WorkGroupsPage() {
    const [workGroups, setWorkGroups] = useState([])
    const [workGroup, setWorkGroup] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const onInputChange = ({ target }) => {
        const name = target.name
        const value = target.value

        eval(`set${capitalizeFirstLetter(name)}`)(value)
    }

    const addWorkGroup = async () => {
        const payload = { name: workGroup }
        try {
            await addWorkGroupsAPI(payload)
        } catch (err) {
            console.log(err)
        }

        toggleModal()

        fetchData()
    }

    async function fetchData() {
        setIsLoading(true)
        getWorkGroupsAPI().then(({ data }) => {
            setWorkGroups(data)
            setIsLoading(false)
        }
        ).catch(err => {
            console.log(err)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <MainLayout>
            <WorkGroupsTable workGroups={workGroups} fetchData={fetchData} toggleModal={toggleModal} isLoading={isLoading} />
            <Modal open={showModal} onClose={toggleModal} toggleModal={toggleModal} title="Adicionar Grupo de Trabalho">
                <Grid container direction="column" gap={4} style={{ padding: '40px 0 0 0' }}>
                    <TextField
                        id="name"
                        name="workGroup"
                        label="Nome"
                        variant="standard"
                        value={workGroup}
                        style={{ width: '100%' }}
                        onChange={onInputChange}
                    />
                    <Box my={1} />
                    <Button variant="contained" disabled={!workGroup} onClick={addWorkGroup}>Adicionar</Button>
                </Grid>
            </Modal>
        </MainLayout>
    )
}

export default WorkGroupsPage