import { useEffect, useContext } from 'react';
import {
    Grid,
    Stepper as MUIStepper,
    Step,
    StepLabel,
    Box
} from '@mui/material';
import ProcessingContent from '../ProcessingContent';
import PreProcessingContent from '../PreProcessingContent';
import ResultsContent from '../ResultsContent';
import StepperContext from '../../../contexts/stepperContext';

function ProcessFileStepper() {
    const { activeStep, labels, setLabels, contents, setContents } = useContext(StepperContext);

    useEffect(() => {
        setLabels(['Configuração', 'Pré-Processamento', 'Resultado'])
        setContents([<ProcessingContent />, <PreProcessingContent />, <ResultsContent />])
    }, [])

    return (
        <Grid sx={{ marginBottom: '100px' }}>
            <MUIStepper activeStep={activeStep}>
                {labels.map((label) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </MUIStepper>
            <Box mb={4} />
            {contents[activeStep]}
        </Grid>
    );
}

export default ProcessFileStepper;